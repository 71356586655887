import { all, takeEvery, call, put } from 'redux-saga/effects';
import { fetchPlans } from '../../services/api/plans';
import { setPlans } from './actions';
import { GET_PLANS } from './types';

export function* getPlans(action) {
  const response = yield call(fetchPlans, action.pageNumber);
  const allPlans = yield call(
    fetchPlans,
    action.pageNumber,
    response.meta.page.total
  );
  yield put(setPlans(allPlans));
}

export default function* plansSaga() {
  yield takeEvery(GET_PLANS, getPlans);
}
