import { SET_PLANS } from './types';

const initialState = {
  plans: null,
};

function plansReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PLANS:
      return {
        ...state,
        plans: action.plans,
      };
    default:
      return state;
  }
}

export default plansReducer;
