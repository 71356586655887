export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SAVE_EDITED_USER = 'SAVE_EDITED_USER';
export const SAVE_EDITED_USER_PROFILE = 'SAVE_EDITED_USER_PROFILE';
export const GET_USER_AUDITS = 'GET_USER_AUDITS';
export const SET_USER_AUDITS = 'SET_USER_AUDITS';
export const GET_USER_PLANS = 'GET_USER_PLANS';
export const SET_USER_PLANS = 'SET_USER_PLANS';
export const GET_USER_SUBSCRIBERS_BY_DATE = 'GET_USER_SUBSCRIBERS_BY_DATE';
export const SET_USER_SUBSCRIBERS_BY_DATE = 'SET_USER_SUBSCRIBERS_BY_DATE';
