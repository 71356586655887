import actions from './actions';

const initState = {
  requesting: false,
  successful: false,
  autoLogin: true,
  idToken: localStorage.getItem('id_token'),
  error: null,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS_DATA:
      return {
        requesting: false,
        successful: true,
        autoLogin: true,
        idToken: action.token,
        error: null,
      };
    case actions.LOGIN_ERROR:
      return {
        requesting: false,
        successful: true,
        autoLogin: true,
        idToken: null,
        error: action.payload,
      };
    case actions.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        error: null,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
