import * as ActionTypes from './types';

let initialState = {
  loadingUsers: false,
  loadingSingleUser: false,
};

function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LOADING_USERS_DATA:
      return {
        ...state,
        loadingUsers: action.value,
      };
    case ActionTypes.SET_LOADING_SINGLE_USER_DATA:
      return {
        ...state,
        loadingSingleUser: action.value,
      };

    default:
      return state;
  }
}

export default loadingReducer;
