import jwtConfig from '@iso/config/jwt.config';
import AuthHelper from '@iso/lib/helpers/authHelper';
import { getToken, setToken, clearToken } from '@iso/lib/helpers/utility';
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';
import { createBrowserHistory } from 'history';
import jwt_decode from 'jwt-decode';
import merge from 'lodash/merge';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API;
let isRefreshing = false;
let subscribers = [];

const history = createBrowserHistory();

const http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('id_token');

    if (token) {
      const checkExpiration = () => {
        const expirationDate = jwt_decode(token).exp;
        const currentTime = Math.round(Date.now().valueOf() / 1000);

        return expirationDate - currentTime <= 43200;
      };

      if (checkExpiration()) {
        if (!isRefreshing) {
          console.log('from refresh');
          isRefreshing = true;
          axios({
            method: 'post',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
              Authorization: `Bearer ${token}`,
            },
            url: baseURL + 'auth/refresh',
          })
            .then((response) => {
              console.log(response);
              setToken(response.data.data.access_token);
              subscribers.forEach((request) => {
                request(response.data.data.access_token);
              });
              subscribers = [];
              isRefreshing = false;
            })
            .catch((error) => {
              isRefreshing = false;
              subscribers = [];
              // history.push("/");
            });
        }
        return new Promise((resolve) => {
          subscribers.push((token) => {
            config.headers.Authorization = `Bearer ${token}`;
            resolve(config);
          });
        });
      }
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      clearToken();
      window.location.reload();
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default http;

// const customHeader = () => {
//   let head = {
//     "Content-Type": "application/vnd.api+json",
//     Accept: "application/vnd.api+json",
//   };
//   if (localStorage.getItem("id_token")) {
//     head["Authorization"] = "Bearer " + localStorage.getItem("id_token");
//   }
//   return head;
// };

// const base = (method, url, data = undefined) => {
//   return fetch(`${jwtConfig.fetchUrl}${url}`, {
//     method,
//     headers: customHeader(),
//     body: JSON.stringify(data),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .then((res) => {
//       console.log(res);
//       return res;
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// };

// const SuperFetch = {};
// ["get", "post", "put", "delete"].forEach((method) => {
//   SuperFetch[method] = base.bind(null, method);
// });
// export default SuperFetch;
