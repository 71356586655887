import {
  GET_USERS,
  SET_USERS,
  GET_USER,
  SET_USER,
  SAVE_EDITED_USER,
  SAVE_EDITED_USER_PROFILE,
  GET_USER_AUDITS,
  SET_USER_AUDITS,
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  GET_USER_PLANS,
  SET_USER_PLANS,
  GET_USER_SUBSCRIBERS_BY_DATE,
  SET_USER_SUBSCRIBERS_BY_DATE,
} from './types';

export const getUsers = (pagination) => {
  return { type: GET_USERS, pagination };
};
export const setUsers = (usersList) => {
  return { type: SET_USERS, usersList };
};
export const getUserData = (id) => {
  return { type: GET_USER, id };
};
export const setUserData = (userData) => {
  return { type: SET_USER, userData };
};
export const getUserPlans = (id) => {
  return { type: GET_USER_PLANS, id };
};
export const setUserPlans = (plans) => {
  return { type: SET_USER_PLANS, plans };
};
export const getUserSubscribersByDate = (id, range) => {
  return { type: GET_USER_SUBSCRIBERS_BY_DATE, id, range };
};
export const setUserSubscribersByDate = (subscribers) => {
  return { type: SET_USER_SUBSCRIBERS_BY_DATE, subscribers };
};
export const getUserProfileData = (id) => {
  return { type: GET_USER_PROFILE, id };
};
export const setUserProfileData = (userProfileData) => {
  return { type: SET_USER_PROFILE, userProfileData };
};
export const saveEditedUser = (data) => {
  return { type: SAVE_EDITED_USER, data };
};
export const saveEditedUserProfile = (data) => {
  return { type: SAVE_EDITED_USER_PROFILE, data };
};

export const getUserAudits = (userId, pageNumber) => ({
  type: GET_USER_AUDITS,
  userId,
  pageNumber,
});
export const setUserAudits = (audits) => ({
  type: SET_USER_AUDITS,
  audits,
});
