import jwtDecode from 'jwt-decode';
import http from './superFetch';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class AuthHelper {
  login = async (userInfo) => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'please fill in the input' };
    }
    console.log(userInfo);
    return await http({
      method: 'post',
      url: 'auth/login',
      data: userInfo,
    })
      .then((response) => {
        if (response.errors) {
          console.log(response.errors);
          return response;
        }
        return this.checkExpirity(response.data.access_token);
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  signup = async (userInfo) => {
    //if (!userInfo.username || !userInfo.password) {
    //  return { error: 'please fill in the input' };
    //}
    return await http({
      method: 'post',
      url: 'auth/check',
      data: userInfo,
    }).then((response) => {
      return this.checkExpirity(response.data.access_token);
    });
  };

  /*
  async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: 'Token expired' };
    }
    return await SuperFetch.get('secret/test', { token })
      .then(response => ({
        status: '200',
        message: 'Success',
      }))
      .catch(error => ({ error: JSON.stringify(error) }));
  }
  */

  // async check(token) {
  //   if (this.checkExpirity(token).error) {
  //     return { error: "Token expired" };
  //   }
  //   return await http({ method: "get", url: "auth/check" })
  //     .then((response) => ({
  //       status: "200",
  //       message: "Success",
  //     }))
  //     .catch((error) => ({ error: JSON.stringify(error) }));
  // }
  checkExpirity = (token) => {
    if (!token) {
      return {
        error: 'not matched',
      };
    }
    try {
      const profile = jwtDecode(token);

      const expiredAt = profile.expiredAt || profile.exp * 1000;

      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      console.log(e);

      return { error: 'Server Error' };
    }
  };
}
export default new AuthHelper();
