import {
  SET_USERS,
  SET_USER,
  SET_USER_AUDITS,
  SET_USER_PROFILE,
  SET_USER_PLANS,
  SET_USER_SUBSCRIBERS_BY_DATE,
} from './types';

let initialState = {
  totalUsers: 0,
  usersList: null,
  userData: null,
  userProfileData: null,
  userAudits: null,
  userPlans: null,
  userSubscribersByDate: null,
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        usersList: action.usersList.usersData,
        totalUsers: action.usersList.totalUsers,
      };
    case SET_USER:
      return {
        ...state,
        userData: action.userData,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfileData: action.userProfileData,
      };
    case SET_USER_AUDITS:
      return {
        ...state,
        userAudits: action.audits,
      };
    case SET_USER_PLANS:
      return {
        ...state,
        userPlans: action.plans,
      };
    case SET_USER_SUBSCRIBERS_BY_DATE:
      return {
        ...state,
        userSubscribersByDate: action.subscribers,
      };
    default:
      return state;
  }
}

export default usersReducer;
