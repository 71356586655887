import http from '@iso/lib/helpers/superFetch';
export const fetchPlans = async (pageNumber = 1, size = 15) => {
  try {
    return await http
      .get(`product-plans?page[number]=${pageNumber}&page[size]=${size}`)
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    return error;
  }
};
