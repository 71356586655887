import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import {
  fetchUsers,
  fetchUserData,
  saveEditedUser,
  saveEditedUserProfile,
  fetchUserAudits,
  fetchUserProfileData,
  fetchUserPlans,
  fetchUserSubscribersByDate,
} from '../../services/api/users';
import {
  GET_USERS,
  GET_USER,
  GET_USER_AUDITS,
  SAVE_EDITED_USER,
  SAVE_EDITED_USER_PROFILE,
  GET_USER_PLANS,
  GET_USER_SUBSCRIBERS_BY_DATE,
} from './types';
import {
  setUsers,
  setUserData,
  setUserAudits,
  setUserProfileData,
  setUserPlans,
  setUserSubscribersByDate,
} from './actions';
import { setLoadingSingleUserData, setLoadingUsers } from '../loading/actions';

export function* getUsers(action) {
  yield put(setLoadingUsers(true));
  const response = yield call(fetchUsers, action.pagination);
  yield put(setUsers(response));
  yield put(setLoadingUsers(false));
}
export function* getUser(action) {
  yield put(setLoadingSingleUserData(true));
  const userData = yield call(fetchUserData, action.id);
  const userProfileData = yield call(fetchUserProfileData, action.id);
  const plans = yield call(fetchUserPlans, action.id, action.pageNumber);
  const audits = yield call(fetchUserAudits, action.id, action.pageNumber);

  yield put(setUserAudits(audits));
  yield put(setUserPlans(plans));
  yield put(setUserData(userData));
  yield put(setUserProfileData(userProfileData));
  yield put(setLoadingSingleUserData(false));
}
export function* getUserAudits(action) {
  const audits = yield call(fetchUserAudits, action.userId, action.pageNumber);

  yield put(setUserAudits(audits));
}
export function* getUserPlans(action) {
  const plans = yield call(fetchUserPlans, action.userId, action.pageNumber);

  yield put(setUserPlans(plans));
}
export function* getUserSubscribersByDate(action) {
  const subscribers = yield call(
    fetchUserSubscribersByDate,
    action.id,
    action.range,
    action.pageNumber
  );

  yield put(setUserSubscribersByDate(subscribers));
}
export function* saveUserData(action) {
  const response = yield call(saveEditedUser, action.data);
  // const profileResponse = yield call(saveEditedUserProfile, action.data)
}
export function* saveUserProfileData(action) {
  const response = yield call(saveEditedUserProfile, action.data);
}

export default function* userSaga() {
  yield takeEvery(GET_USERS, getUsers);
  yield takeEvery(GET_USER, getUser);
  yield takeEvery(GET_USER_AUDITS, getUserAudits);
  yield takeEvery(GET_USER_PLANS, getUserPlans);
  yield takeEvery(GET_USER_SUBSCRIBERS_BY_DATE, getUserSubscribersByDate);
  yield takeEvery(SAVE_EDITED_USER, saveUserData);
  yield takeEvery(SAVE_EDITED_USER_PROFILE, saveUserProfileData);
}
