import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken, setToken } from '@iso/lib/helpers/utility';
import AuthHelper from '@iso/lib/helpers/authHelper';
import actions from './actions';

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token, email, password } = payload;

    if (token) {
      const validToken = yield call(AuthHelper.login, token);
      const isValid = yield call(AuthHelper.checkExpirity, validToken);

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: validToken,
        profile: 'Profile',
      });
    } else {
      const response = yield call(AuthHelper.login, payload);

      if (response.token) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          payload: response,
        });
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          payload: response.response.data,
        });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ payload }) {
    // yield call(setToken, payload.payload.token);
    if (payload) {
      yield put({
        type: actions.LOGIN_SUCCESS_DATA,
        token: payload.token,
      });
      yield localStorage.setItem('id_token', payload.token);
      // console.log(history.location);
      // const token = yield getToken().get("idToken");
      // console.log(token);
      history.push('/dashboard/users');
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}

export function* signUp() {
  yield takeEvery(actions.SIGNUP_REQUEST, function* ({ payload }) {
    if (payload.email && payload.password) {
      const validToken = yield call(AuthHelper.signup, payload);
      yield put({
        type: actions.SIGNUP_SUCCESS,
        token: validToken,
        profile: 'Profile',
      });
    } else {
      /*
      const response =  yield call(AuthHelper.login, username, password );
      if (response.token) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: payload.token,
          profile: 'Profile'
        });
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          payload: response
        });
      }
      */
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(signUp),
  ]);
}
