import http from '@iso/lib/helpers/superFetch';
import AuthHelper from '@iso/lib/helpers/authHelper';
import moment from 'moment';

export const fetchUsers = async (pagination) => {
  try {
    return await http
      .get(
        `users?page[number]=${pagination.page}&page[size]=${pagination.perPage}`
      )
      .then((response) => {
        const usersData = [];
        const totalUsers = response.data.meta.page.total;
        response.data.data.forEach((el) => {
          const user = {
            key: el.attributes.email,
            id: el.id,
            name: el.attributes.name,
            email: el.attributes.email,
            active: el.attributes.active ? 'Active' : 'Inactive',
            status: el.attributes.status,
          };
          usersData.push(user);
        });
        return { usersData, totalUsers };
      });
  } catch (error) {
    return error;
  }
};
export const fetchUserData = async (id) => {
  try {
    return await http.get(`users/${id}`).then((response) => {
      return response.data;
    });
  } catch (error) {
    return error;
  }
};
export const fetchUserProfileData = async (id) => {
  try {
    return await http.get(`profiles/${id}`).then((response) => {
      return response.data;
    });
  } catch (error) {
    return error;
  }
};
// export const fetchUserAudits = async (pageNumber = 1) => {
//   try {
//     return await http
//       .get(`audits?page[number]=${pageNumber}`)
//       .then((response) => {
//         console.log(response.data.data);

//         return response.data;
//       });
//   } catch (error) {
//     return error;
//   }
// };
export const fetchUserAudits = async (userId, pageNumber = 1, size = 10) => {
  try {
    return await http
      .get(
        `users/${userId}/auditeds?page[number]=${pageNumber}&page[size]=${size}`
      )
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    return error;
  }
};
export const fetchUserPlans = async (userId, pageNumber = 1, size) => {
  try {
    return await http
      .get(
        `users/${userId}/product-plans?page[number]=${pageNumber}&page[size]=${size}`
      )
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    return error;
  }
};
export const fetchUserSubscribersByDate = async (
  userId,
  range,
  pageNumber = 1,
  size = 10
) => {
  const start = moment(range[0]).format('YYYY-MM-DD');
  const end = moment(range[1]).format('YYYY-MM-DD');

  try {
    return await http
      .get(
        `users/${userId}/subscribers?filter[created-at-range][start]=${start}&filter[created-at-range][end]=${end}&page[number]=${pageNumber}&page[size]=${size}`
      )
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    return error;
  }
};

export const saveEditedUser = async (data) => {
  try {
    return await http({
      method: 'patch',
      url: `users/${data.data.id}`,
      data,
    }).then((response) => {
      return response.data.data;
    });
  } catch (error) {
    return error;
  }
};
export const saveEditedUserProfile = async (data) => {
  try {
    return await http({
      method: 'patch',
      url: `profiles/${data.data.id}`,
      data,
    }).then((response) => {
      return response.data.data;
    });
  } catch (error) {
    return error;
  }
};
