import { GET_PLANS, SET_PLANS } from './types';

export const getPlans = (pageNumber) => ({
  type: GET_PLANS,
  pageNumber,
});
export const setPlans = (plans) => ({
  type: SET_PLANS,
  plans,
});
